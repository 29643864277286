import { useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
const AccountInfo = () => {
	const [copiedText, setCopiedText] = useState('');
 	const copyText = (text) => {
    	setCopiedText(text);
    	setTimeout(() => {
    		setCopiedText('');
    	}, 3000);
  	};
	return (
		<div className="d-flex justify-content-center">
			<div className="account-info w-100">
				<div className="card border border-light my-2">
				  <div className="card-body text-start">
				  	<div className="table-responsive">
					    <table className="table table-bordered border-light">
						  <tbody>
						    <tr className="border border-0">
						      <th className="bg-transparent border border-0 text-white" scope="col">UPI ID</th>
						      <tr className="bg-transparent border border-0 text-white float-end">
						      	60503152172@MAHB0000831.IFSC.NPCI 
						      	<CopyToClipboard text="60503152172@MAHB0000831.IFSC.NPCI" onCopy={() => copyText('UPI ID')}>
				                	{ copiedText !== 'UPI ID' ? <i className="far fa-copy text-warning ms-2" style={{ cursor: 'pointer' }}></i> : <i class="fas fa-check text-success ms-2"></i>}
				              	</CopyToClipboard>
						      </tr>
						    </tr>
						    <tr>
						      <th className="bg-transparent border border-0 text-white" scope="col">Holder Name</th>
						      <tr className="bg-transparent border border-0 text-white float-end">
						      	JITENDRA SINGH 
						      	<CopyToClipboard text="JITENDRA SINGH" onCopy={() => copyText('Holder Name')}>
					                { copiedText !== 'Holder Name' ? <i className="far fa-copy text-warning ms-2" style={{ cursor: 'pointer' }}></i> : <i class="fas fa-check text-success ms-2"></i>}
					            </CopyToClipboard>
					          </tr>
						    </tr>
						    <tr>
						      <th className="bg-transparent border border-0 text-white" scope="col">Bank Name</th>
						      <tr className="bg-transparent border border-0 text-white float-end">
						      	MAHARSHTRA BANK 
						      	<CopyToClipboard text="MAHARSHTRA BANK" onCopy={() => copyText('Bank Name')}>
					                { copiedText !== 'Bank Name' ? <i className="far fa-copy text-warning ms-2" style={{ cursor: 'pointer' }}></i> : <i class="fas fa-check text-success ms-2"></i>}
					            </CopyToClipboard>
						      </tr>
						    </tr>
						    <tr>
						      <th className="bg-transparent border border-0 text-white" scope="col">Max Amount</th>
						      <tr className="bg-transparent border border-0 text-white float-end">
						      	INR. 1,00,000 
						      </tr>
						    </tr>
						  </tbody>
						</table>
					</div>
				  </div>
				</div>
			</div>
		</div>
	);
};
export default AccountInfo;




