import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLoginHook } from '../../hooks/loginHook';
import { LoginModal, SignUpModal } from '../modal';
const Header = () => {
    const hideButton = useSelector((state) => state.showHide.hideButton);
    const { handleLogout } = useLoginHook();
    
	return(
		<>
			<nav className="navbar navbar-expand navbar-light topbar mb-md-4 static-top shadow" style={{backgroundColor: '#2e2b71'}}>

                {/*Sidebar Toggle (Topbar)*/}
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>
                <div className="jsr-header-menu">
                    <ul className="d-sm-inline-block flex-nowrap overflow-auto mr-auto ml-md-3 my-2 my-md-0 mw-100 text-nowrap">
                        <li><a className="text-decoration-none" href="/"><img src="img/jsrplay.svg" width="26" alt="" /><span>JSRPlay Exch</span></a></li>
                        <li><a className="text-decoration-none" href="/"><img src="img/all-casino.svg" width="26" alt="" /><span>All Casino</span></a></li>
                        <li><a className="text-decoration-none" href="/"><img src="img/aviator.svg" width="26" alt="" /><span>Aviator</span></a></li>
                        <li><a className="text-decoration-none" href="/"><img src="img/teenpatti.svg" width="26" alt="" /><span>Teenpatti</span></a></li>
                        <li><a className="text-decoration-none" href="/"><img src="img/teenpatti.svg" width="26" alt="" /><span>Football</span></a></li>
                        <li><a className="text-decoration-none" href="/"><img src="img/casino.svg" width="26" alt="" /><span>Casino</span></a></li>
                        <li><a className="text-decoration-none" href="/"><img src="img/teenpatti.svg" width="26" alt="" /><span>Cricket</span></a></li>
                    </ul>
                </div>

                {/*Topbar Navbar*/}
                <ul className="navbar-nav ml-auto">

                    {
                        !hideButton ? (
                        <>
                        <li className="nav-item dropdown no-arrow mx-1">
                            <button className="d-sm-inline-block btn btn-sm border-light shadow-sm" data-bs-toggle="modal" data-bs-target="#Modal1">
                                <i className="fas fa-user fa-sm text-light"></i> 
                                <span className="text-light"> Login </span> 
                            </button>
                        </li>

                        <li className="nav-item dropdown no-arrow mx-1">
                            <button className="d-sm-inline-block btn btn-sm btn-light shadow-sm" data-bs-toggle="modal" data-bs-target="#Modal2">
                                <i className="fas fa-lock fa-sm text-dark"></i> <span>Sign up</span>
                            </button>
                        </li>
                        </>
                    ) : (
                        <li className="nav-item dropdown">
                          <Link className="nav-link bg-light text-dark rounded-circle mr-2" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ height:"2rem", width:"2rem", fontSize:"large", padding:"9px" }}>
                            <i className="fas fa-user fa-sm"></i> 
                          </Link>
                          <ul className="dropdown-menu mr-2 ml-auto fs-6 p-2" style={{backgroundColor:'#26293d'}}>
                            <li>
                                <Link className="dropdown-item" to="/deposit" style={{color:'#ffffff'}}>
                                    <i className="fas fa-fw fa-plus-circle"></i>
                                    Deposit
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/withdrawal" style={{color:'#ffffff'}}>
                                    <i className="fas fa-fw fa-minus-circle"></i>
                                    Withdrawal
                                </Link>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <Link className="dropdown-item" to="/" onClick={handleLogout} style={{color:'#ffffff'}}>
                                    <i className="fas fa-fw fa-sign-out-alt"></i>
                                    Logout
                                </Link>
                            </li>
                          </ul>
                        </li>
                    )}
                </ul>
            </nav>
            <div className="marquee-container d-md-none">
                <div className="jsr-header-menu marquee-content">
                    <ul className="my-2 text-nowrap">
                        <li className="marquee-item"><a href="/"><img src="img/jsrplay.svg" width="26" alt="" /><span>JSRPlay Exch</span></a></li>
                        <li className="marquee-item"><a href="/"><img src="img/all-casino.svg" width="26" alt="" /><span>All Casino</span></a></li>
                        <li className="marquee-item"><a href="/"><img src="img/aviator.svg" width="26" alt="" /><span>Aviator</span></a></li>
                        <li className="marquee-item"><a href="/"><img src="img/teenpatti.svg" width="26" alt="" /><span>Teenpatti</span></a></li>
                        <li className="marquee-item"><a href="/"><img src="img/teenpatti.svg" width="26" alt="" /><span>Football</span></a></li>
                        <li className="marquee-item"><a href="/"><img src="img/casino.svg" width="26" alt="" /><span>Casino</span></a></li>
                        <li className="marquee-item"><a href="/"><img src="img/teenpatti.svg" width="26" alt="" /><span>Cricket</span></a></li>
                    </ul>
                </div>
            </div>
            <LoginModal id="Modal1"/>
            <SignUpModal id="Modal2"/>
		</>

	)
}

export default Header;