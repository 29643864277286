import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useFetch from './fetchHook';
export const useSiteHook = () => {
	const { fetchApi } = useFetch();
	const [ sites, setSites ] = useState();
	const login = useSelector((state) => state.login.login);
	const prevLogin = useRef(login);
	console.log('login', login);
	console.log('prevLogin', prevLogin);
	useEffect(() => {
        if (login && prevLogin.current === false) {
            // Call getId only when login state changes from false to true
            console.log('Login successful, fetching sites...');
            getId(); // Automatically fetch sites after login
        } else if (prevLogin.current === true && login === false) {
            // Clear sites on logout
            console.log('Logout successful, clearing sites...');
            setSites([]); 
        }
        prevLogin.current = login;
    }, [login]);
	const createId = async (site) => {
		const loginData = localStorage.getItem('loginData');
		if(loginData) {
			const parsedLoginData = JSON.parse(loginData);
			const token = loginData ? parsedLoginData.token : null;
			console.log('token', token);
			const body = {
				id: JSON.parse(loginData).id,
				site: site
			}
			const customHeaders = {
			  'AuthorizationToken': `Bearer ${token}`,
			};
			const result = await fetchApi('https://1bmeqg3e7c.execute-api.us-east-1.amazonaws.com/v1/clients', 'PUT', body, customHeaders);
		}
				
	}
	const getId = async (site) => {
		const loginData = localStorage.getItem('loginData');
		if(loginData) {
			const parsedLoginData = JSON.parse(loginData);
			const token = loginData ? parsedLoginData.token : null;
			const customHeaders = {
			  'AuthorizationToken': `Bearer ${token}`,
			};
			const result = await fetchApi('https://1bmeqg3e7c.execute-api.us-east-1.amazonaws.com/v1/clients/sites', 'GET', null, customHeaders);
			console.log('sites are setting...');
			setSites(result.data);
			console.log('result', result);
			console.log('sites setting done');
		}
	}
	return {
		createId,
		getId,
		sites
	};
};