import { useState } from 'react';
import { toast } from 'react-toastify';
export const useAddAccount = () => {
	const [ accountInfo, setAccountInfo ] = useState({
		holderName: '',
		bankName: '',
		branch: '',
		accountNumber: '',
		ifscCode: ''
	});

	const [ accountInfoValid, setAccountInfoValid ] = useState({
		holderName: false,
		bankName: false,
		branch: false,
		accountNumber: false,
		ifscCode: false
	});

	const [touchedAccount, setTouchedAccount] = useState({
		holderName: false,
		bankName: false,
		branch: false,
		accountNumber: false,
		ifscCode: false
	});

	const handleSubmit = (event) => {
		event.preventDefault();
		if(!accountInfoValid.holderName || !accountInfoValid.bankName || !accountInfoValid.branch || !accountInfoValid.accountNumber || !accountInfoValid.ifscCode){
			toast.error('Please Check Error Fields.', {
		      theme: "colored",
		    });
		    return;
		} else {
			resetValidation();
		}
	};

	const resetValidation = () => {
		setAccountInfo({ ...accountInfo, holderName: '', bankName: '', branch: '', accountNumber: '', ifscCode: '' });
		setAccountInfoValid({ ...accountInfoValid, holderName: false, bankName: false, branch: false, accountNumber: false, ifscCode: false });
	};

	const onHandleHolderName = (e) => {
		const fullNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
		const value = e.target.value;
		const isValid = fullNameRegex.test(value);
		setAccountInfo({ ...accountInfo, holderName: value });
		setAccountInfoValid({ ...accountInfoValid, holderName: isValid });
		setTouchedAccount((prev) => ({ ...prev, holderName: true }));
	};

	const onHandleBankName = (e) => {
		const fullNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
		const value = e.target.value;
		const isValid = fullNameRegex.test(value);
		setAccountInfo({ ...accountInfo, bankName: value });
		setAccountInfoValid({ ...accountInfoValid, bankName: isValid });
		setTouchedAccount((prev) => ({ ...prev, bankName: true }));
	};

	const onHandleBranchName = (e) => {
		const fullNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
		const value = e.target.value;
		const isValid = fullNameRegex.test(value);
		setAccountInfo({ ...accountInfo, branch: value });
		setAccountInfoValid({ ...accountInfoValid, branch: isValid });
		setTouchedAccount((prev) => ({ ...prev, branch: true }));
	};

	const onHandleAccountNmbr = (e) => {
		const value = e.target.value;
		setAccountInfo({ ...accountInfo, accountNumber: value });
		setAccountInfoValid({ ...accountInfoValid, accountNumber: value.length < 12 || value.length > 14 ? false : true });
		setTouchedAccount((prev) => ({ ...prev, accountNumber: true }));
	};

	const onHandleIfscCode = (e) => {
		const ifscCodeRegex = /^[A-Z]{4}0\d{6}$/
		const value = e.target.value.toUpperCase();
		const isValid = ifscCodeRegex.test(value);
		setAccountInfo({ ...accountInfo, ifscCode: value });
		setAccountInfoValid({ ...accountInfoValid, ifscCode: isValid });
		setTouchedAccount((prev) => ({ ...prev, ifscCode: true }));
	};
	console.log('accountInfoValid', accountInfoValid);
	return {
		accountInfo,
		accountInfoValid,
		touchedAccount,
		onHandleHolderName,
		onHandleBankName,
		onHandleBranchName,
		onHandleAccountNmbr,
		onHandleIfscCode,
		handleSubmit
	};
};