import { useState } from "react";
import { useDispatch } from "react-redux";
import { show, hide } from '../redux/showhide/action';
import { login, logout } from '../redux/login/action';
import useFetch from './fetchHook';
import { toast } from 'react-toastify';
export const useLoginHook = () => {
	const dispatch = useDispatch();
	const { fetchApi } = useFetch();
 	const [credentials, setCredentials] = useState({
	    username: '',
	    password: ''
  	});
  	const [credentialsRegister, setCredentialsRegister] = useState({
	    username: '',
	    password: ''
  	});
  	const [isUsernameValid, setIsUsernameValid] = useState(false);
  	const [isUsernameRegisterValid, setIsUsernameRegisterValid] = useState(false);
  	const [isPasswordValid, setIsPasswordValid] = useState(false);
  	const [isPasswordRegisterValid, setIsPasswordRegisterValid] = useState(false);
  	const [touched, setTouched] = useState({
  		username: false,
  		password: false
  	});
  	const demoCredentials = {
	    username: 'test01',
	    password: 'Test@123'
  	};
  	const [error, setError] = useState(null);

 	const handleLogin = async (e) => {
 		e.preventDefault();
 		if (!isUsernameValid || !isPasswordValid) {
		    // Show error if any field is invalid
		    toast.error('Please Check Errors in Username or Password', {
		      theme: "colored",
		    });
		    resetValidation();
		    return; // Stop further execution if there's an error
		 }
	    try {
	      const body = {
 		  	username: credentials.username,
 		  	password: credentials.password
 		  }
 		  console.log('body', body);
	      const result = await fetchApi('https://1bmeqg3e7c.execute-api.us-east-1.amazonaws.com/v1/login', 'POST', body);
	      console.log('result', result);
	      const data = result.data;
	      if (result) {
	      	toast.success(result.msg, {
			  theme: "colored"
			});
	      }
	      if(data){
	      	localStorage.setItem('loginData', JSON.stringify(data));
	      	document.getElementById('closeModalButton').click();
	        resetCredentials();
	        dispatch(show());
	        dispatch(login());
	        // window.location.reload();
	        return true;
	      } 
	    } catch (err) {
	      // Handle login err
	      console.err('err logging in:', err);
	      setError(err.message);
	    }
 	};

 	const handleRegister = async (e) => {
 		e.preventDefault();
 		if (!isUsernameRegisterValid || !isPasswordRegisterValid) {
		    toast.error('Please Check Errors in Username or Password', {
			  theme: "colored"
			});
			resetValidation();
		    return;
		}
 		const body = {
 			username: credentialsRegister.username,
 			password: credentialsRegister.password,
 		}
 		const result = await fetchApi('https://1bmeqg3e7c.execute-api.us-east-1.amazonaws.com/v1/clients', 'POST', body);
 		if(result.body && result.body.status === true){
 			console.log("inside result.body.status")
 			toast.success(result.body.msg, {
			  theme: "colored"
			});
			document.getElementById('closeSignUpModalButton').click();
			const autoLoginCredentials = {
				username: credentialsRegister.username,
				password: credentialsRegister.password
			};
			try{
				const result = await fetchApi('https://1bmeqg3e7c.execute-api.us-east-1.amazonaws.com/v1/login', 'POST', autoLoginCredentials);
		      	console.log('result', result);
		      	const data = result.data;
		      	if (result) {
			      	toast.success(result.msg, {
					  theme: "colored"
					});
		      	}
		      	if(data){
			      	localStorage.setItem('loginData', JSON.stringify(data));
			      	document.getElementById('closeModalButton').click();
			        resetCredentials();
			        dispatch(show());
			        dispatch(login());
			        // window.location.reload();
			        return true;
		      	} 
			} catch(err) {
				console.err('err logging in:', err);
	      		setError(err.message);
			};
 		};
 		console.log('result', result);
 	};

 	const demoLogin = async (e) => {
 		e.preventDefault();
 		console.log('credentials', credentials);
	    try {
	      const body = {
 		  	username: demoCredentials.username,
 		  	password: demoCredentials.password
 		  }
	      const result = await fetchApi('https://1bmeqg3e7c.execute-api.us-east-1.amazonaws.com/v1/login', 'POST', body);
 		  console.log('body', body);
	      console.log('result', result);
	      const data = result.data;
	      if (result) {
	      	toast.success(result.msg, {
			  theme: "colored"
			});
	      };
	      if(data){
	      	localStorage.setItem('loginData', JSON.stringify(data));
	      	document.getElementById('closeModalButton').click();
	        resetCredentials();
	        dispatch(show());
	        dispatch(login());
	        // window.location.reload();
	      }; 
	    } catch (error) {
	      // Handle login error
	      console.error('Error logging in:', error);
	      setError(error.message);
	      console.log('error', error);
	    }
 	}

 	const handleLogout = async () => {
	    try {
	    	const loginData = localStorage.getItem('loginData');
	    	const token = (JSON.parse(loginData)).token;
	    	const customHeaders = {
			  'AuthorizationToken': `Bearer ${token}`,
			};
	    	console.log('customHeaders', customHeaders);
		    const result = await fetchApi('https://1bmeqg3e7c.execute-api.us-east-1.amazonaws.com/v1/logout', 'POST', null, customHeaders);
		    if (result.status === 200) {
		    	toast.error(result.msg, {
				  theme: "colored"
				});
		    	localStorage.clear(); 
		    	console.log('User logged out successfully');
		    	dispatch(hide());
		    	dispatch(logout());
		    	// window.location.reload();
		    }
		    
		    
		      // Additional actions like redirecting or clearing the Redux store can be done here
	    } catch (error) {
	      console.error('Error logging out:', error);
	    }
	};

 	const resetCredentials = () => {
 		setCredentials({
 			username: '',
 			password: ''
 		});

 		setCredentialsRegister({
 			username: '',
 			password: ''
 		});
 	};

 	const resetValidation = () => {
 		setTouched({ username: true, password: true });
 		setIsUsernameValid(false);
 		setIsPasswordValid(false);
 		setIsUsernameRegisterValid(false);
 		setIsPasswordRegisterValid(false);
 	};

 	// Event change of username
  	const onChangeUserName = (e) => {
  		const value = e.target.value;
    	setCredentials({ ...credentials, username: e.target.value });
    	value.length < 5 ? setIsUsernameValid(false) : setIsUsernameValid(true);
    	setTouched((prev) => ({ ...prev, username: true }));
  	};

  	// Event change of password
  	const onChangePassword = (e) => {
  		const value = e.target.value;
    	setCredentials({ ...credentials, password: e.target.value });
    	value.length < 8 ? setIsPasswordValid(false) : setIsPasswordValid(true);
    	setTouched((prev) => ({ ...prev, password: true }));
  	};

  	// Event change of username
  	const onChangeUserNameRegister = (e) => {
  		const value = e.target.value;
    	setCredentialsRegister({ ...credentialsRegister, username: e.target.value });
    	value.length < 5 ? setIsUsernameRegisterValid(false) : setIsUsernameRegisterValid(true);
    	setTouched((prev) => ({ ...prev, username: true }));
  	};

  	// Event change of password
  	const onChangePasswordRegister = (e) => {
  		const value = e.target.value;
    	setCredentialsRegister({ ...credentialsRegister, password: e.target.value });
    	value.length < 8 ? setIsPasswordRegisterValid(false) : setIsPasswordRegisterValid(true);
    	setTouched((prev) => ({ ...prev, password: true }));
  	};
  	
 	return{ 
 		onChangeUserName,
 		onChangeUserNameRegister,
 		onChangePassword,
 		onChangePasswordRegister,
 		credentials,
 		credentialsRegister,
 		demoLogin,
 		handleLogin,
 		handleRegister,
 		isUsernameValid,
 		isUsernameRegisterValid,
 		isPasswordValid,
 		isPasswordRegisterValid,
 		touched,
 		handleLogout
 	};
 }