import React, { useEffect } from 'react';
import Header from './subcomponents/header';
import Sidebar from './subcomponents/sidebar';
import Footer from './subcomponents/footer';
import MultiId from './components/multiid';
import Deposit from './components/deposit';
import Withdrawal from './components/withdrawal';
import { useDispatch } from "react-redux";
import { Routes, Route } from 'react-router-dom';
import { show, hide } from './redux/showhide/action';
const Layout = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		const loginData = localStorage.getItem('loginData');
		var token = null;
		if(loginData){
			try {
	            const parsedData = JSON.parse(loginData);
	            token = parsedData.token;
	        } catch (error) {
	            console.error('Error parsing login data:', error);
	        }
		}
		if(token){
			dispatch(show());
		} else {
			dispatch(hide());
		};
	}, [dispatch]);
	return (
	<>
		{/*// Page Wrapper*/}
	    <div id="wrapper">

	        {/*Sidebar*/}
	        	<Sidebar />
	        {/*End of Sidebar*/}

	        {/*Content Wrapper */}
	        <div id="content-wrapper" className="d-flex flex-column">

	            {/*Main Content */}
	            <div id="content">

	                {/*Topbar */}
	                	<Header />
	                {/*End of Topbar */}

	                {/*Begin Page Content */}
	                <div className="container-fluid">	
		            	<Routes>
		            		<Route exact path="/" element={<MultiId/>} />
		            		<Route path="/deposit" element={<Deposit/>} />
		            		<Route path="/withdrawal" element={<Withdrawal/>} />
		            	</Routes>
	                    {/*Content Row */}
	                </div>
	                {/*/.container-fluid */}

	            </div>
	            {/*End of Main Content*/}

	            {/*Footer*/}
	            	<Footer />
	            {/*End of Footer*/}

	        </div>
	        {/*End of Content Wrapper*/}

	    </div>
    	{/*End of Page Wrapper*/}

    	{/*Scroll to Top Button*/}
	    <a className="scroll-to-top rounded" href="/page-top">
	        <i className="fas fa-angle-up"></i>
	    </a>
	</>
	)
}

export default Layout;