import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useSiteHook } from '../../hooks/siteHook';

const MultiId = () => {
    // State to trigger a re-render
    const { createId, sites } = useSiteHook();
    const [refresh, setRefresh] = useState(false);
    const [token, setToken] = useState();
     useEffect(() => {
        const loginData = localStorage.getItem('loginData');
        if(loginData){
            const parsedLoginData = JSON.parse(loginData);
            if (parsedLoginData.token) {
                setToken(parsedLoginData.token);
            }
        };
    }, [refresh]); // Re-fetch sites when refresh state changes

    const handleCreateId = async (siteName) => {
        await createId(siteName); // Assuming createId is asynchronous
        setRefresh(prevState => !prevState); // Toggle refresh state to re-render
    };
    const hideButton = useSelector((state) => state.showHide.hideButton);
    return (
        <>
            <div className="text-center col-xl-12 mb-4">
                <div className="">
                    <ul className="nav nav-pills d-inline-flex justify-content-between align-items-center tab-wrapper-create shadow border border-white" id="pills-tab" role="tablist" style={{padding: '10px 0px'}}>
                      <li className="nav-item" role="presentation">
                        <a href="/" className="tab px-sm-5 px-3 description-text" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">My ID's</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a href="/" className="tab px-sm-5 px-3 description-text active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Create ID</a>
                      </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">  
                    <div className="row d-flex justify-content-center">               
                        { hideButton && Array.isArray(sites) && sites.length ? (
                            sites.map((site, index ) => (
                            <div key={index} className="col-xl-4 col-md-6 mb-4">
                                <div className="card border-bottom-primary shadow h-auto p-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: "25rem"}} src={ site.site === 'diamond' ? 'img/cricguru_logo.png' : site.site === 'lotus' ? 'img/lotus_logo.png' : site.site === 'skyexch' ? 'img/sky_exch_logo.png' : 'No Image Found' } alt="..." />
                                            <div className="col">
                                                <div className="text-sm font-weight-bold text-primary text-uppercase mb-2 text-center">
                                                    <Link to="/" className="text-white text-decoration-none"> {site.site} </Link>
                                                </div>
                                                <p className="m-0 text-center text-white">Username: <span>{site.username}</span></p>
                                                <p className="text-center text-white">Password: <span>{site.password}</span></p>
                
                                                <p className="m-0 d-block text-center">
                                                    <Link to="/" className="btn btn-primary btn-circle withdraw" aria-label="Withdraw"></Link>
                                                    <Link to="/" className="btn btn-success btn-circle checkbalance mx-1" aria-label="CheckBalance"></Link>
                                                    <Link to="/" className="btn btn-warning btn-circle deposit" aria-label="Deposit"></Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))
                            ) : (
                                <>
                                </>
                            )
                        }
                    </div>
                  </div>
                  <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">
                        {((!Array.isArray(sites) || sites.length === 0) && !token) && (
                            ['diamond', 'lotus', 'skyexch'].map((siteName) => {
                                const logoSrc = {
                                    diamond: "img/cricguru_logo.png",
                                    lotus: "img/lotus_logo.png",
                                    skyexch: "img/sky_exch_logo.png",
                                };

                                const siteLink = {
                                    diamond: "https://cricguru9.com/",
                                    lotus: "https://www.lotusbook247.com/",
                                    skyexch: "https://www.skyexch.vip/",
                                };

                                return (
                                    <div className="col-xl-4 col-md-6 mb-4" key={siteName}>
                                        <div className="card border-bottom-primary shadow h-auto p-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: "25rem"}} src={logoSrc[siteName]} alt={`${siteName} logo`} />
                                                    <div className="col">
                                                        <div className="text-sm font-weight-bold text-primary text-uppercase mb-2 text-center">
                                                            <a href={siteLink[siteName]} className="text-white text-decoration-none" target="_blank" rel="noreferrer">
                                                                {siteName.toUpperCase()}
                                                            </a>
                                                        </div>
                                                        <button className="btn btn-block btn-light fw-semibold" style={{color:'#26283c'}} onClick={() => handleCreateId(siteName)}>
                                                            Create ID
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )}

                        {((!Array.isArray(sites) || sites.length === 0) && token) && (
                            ['diamond', 'lotus', 'skyexch'].map((siteName) => {
                                const logoSrc = {
                                    diamond: "img/cricguru_logo.png",
                                    lotus: "img/lotus_logo.png",
                                    skyexch: "img/sky_exch_logo.png",
                                };

                                const siteLink = {
                                    diamond: "https://cricguru9.com/",
                                    lotus: "https://www.lotusbook247.com/",
                                    skyexch: "https://www.skyexch.vip/",
                                };

                                return (
                                    <div className="col-xl-4 col-md-6 mb-4" key={siteName}>
                                        <div className="card border-bottom-primary shadow h-auto p-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: "25rem"}} src={logoSrc[siteName]} alt={`${siteName} logo`} />
                                                    <div className="col">
                                                        <div className="text-sm font-weight-bold text-primary text-uppercase mb-2 text-center">
                                                            <a href={siteLink[siteName]} className="text-white text-decoration-none" target="_blank" rel="noreferrer">
                                                                {siteName.toUpperCase()}
                                                            </a>
                                                        </div>
                                                        <button className="btn btn-block btn-light fw-semibold" style={{color:'#26283c'}} onClick={() => handleCreateId(siteName)}>
                                                            Create ID
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )}

                        {Array.isArray(sites) && sites.length > 0 && (
                            ['diamond', 'lotus', 'skyexch'].map((siteName) => {
                                const siteExists = sites.some((site) => site.site === siteName);

                                if (!siteExists) {
                                    const logoSrc = {
                                        diamond: "img/cricguru_logo.png",
                                        lotus: "img/lotus_logo.png",
                                        skyexch: "img/sky_exch_logo.png",
                                    };

                                    const siteLink = {
                                        diamond: "https://cricguru9.com/",
                                        lotus: "https://www.lotusbook247.com/",
                                        skyexch: "https://www.skyexch.vip/",
                                    };

                                    return (
                                        <div className="col-xl-4 col-md-6 mb-4" key={siteName}>
                                            <div className="card border-bottom-primary shadow h-auto p-2">
                                                <div className="card-body">
                                                    <div className="row no-gutters align-items-center">
                                                        <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: "25rem"}} src={logoSrc[siteName]} alt={`${siteName} logo`} />
                                                        <div className="col">
                                                            <div className="text-sm font-weight-bold text-primary text-uppercase mb-2 text-center">
                                                                <a href={siteLink[siteName]} className="text-white text-decoration-none" target="_blank" rel="noreferrer">
                                                                    {siteName.toUpperCase()}
                                                                </a>
                                                            </div>
                                                            <button className="btn btn-block btn-light fw-semibold" style={{color:'#26283c'}} onClick={() => handleCreateId(siteName)}>
                                                                Create ID
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }

                                return null;
                            })
                        )}
                    </div>
                  </div>
            </div>
        </>
    )
}

export default MultiId;
