import { useState } from "react";
import { toast } from 'react-toastify';
export const usePaymentSlip = () => {
	const [ bankInfo, setBankInfo ] = useState({
		utr: '',
		amount: ''
	});

	const [ bankInfoValid, setBankInfoValid ] = useState({
		utr: false,
		amount: false
	});

	const [touched, setTouched] = useState({
  		utr: false,
  		amount: false
  	});

	const handleSubmit = () => {
		if(!bankInfoValid.utr || !bankInfoValid.amount){
			toast.error('Please Check Error Fields.', {
		      theme: "colored",
		    });
		    resetValidation();
		};
	};

	const resetValidation = () => {
		setBankInfo({ ...bankInfo, utr: '', amount: '' });
		setBankInfoValid({ ...bankInfoValid, utr: false, amount: false });
	};

	const onHandleUtr = (e) => {
		const value = e.target.value;
		setBankInfo({ ...bankInfo, utr: value });
		setBankInfoValid({ ...bankInfoValid, utr: value.length < 6 || value.length > 12 ? false : true });
		setTouched((prev) => ({ ...prev, utr: true }));
	};

	const onHandleAmount = (e) => {
		const value = e.target.value;
		setBankInfo({ ...bankInfo, amount: value });
		setBankInfoValid({ ...bankInfoValid, amount: value.length < 3  ? false : true });
		setTouched((prev) => ({ ...prev, amount: true }));
	};

	const calculateAmount = (amount) => {
		setBankInfo((prevBankInfo) => ({
			...prevBankInfo,
			amount: Number(prevBankInfo.amount || 0) + Number(amount) 
		}));
	};

	return{
		bankInfo,
		setBankInfo,
		onHandleAmount,
		onHandleUtr,
		bankInfoValid,
		touched,
		handleSubmit,
		calculateAmount
	};
};
