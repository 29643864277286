import { useValidationHook } from '../../hooks/validationHook';
import { useWithdrawalSlip } from '../../hooks/withdrawalslipHook';
import { useAddAccount } from '../../hooks/addaccountHook';
const Withdrawal = () => {
	useValidationHook();
	const {
		bankInfo,
		bankInfoValid,
		touched,
		onHandleAccountNumber,
		onHandleAccountName,
		onHandleAmount,
		calculateAmount,
		handleWithdrawalSubmit
	} = useWithdrawalSlip();
	const {
		accountInfo,
		accountInfoValid,
		touchedAccount,
		onHandleHolderName,
		onHandleBankName,
		onHandleBranchName,
		onHandleAccountNmbr,
		handleSubmit,
		onHandleIfscCode,
	} = useAddAccount();
	return (
		<div>
			<div className="withdrawal text-white">
				<ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
				  <li className="nav-item" role="presentation">
				    <button className="nav-link text-white" id="pills-add-tab" data-bs-toggle="pill" data-bs-target="#pills-add" type="button" role="tab" aria-controls="pills-add" aria-selected="true">Add Account</button>
				  </li>
				  <li className="nav-item" role="presentation">
				    <button className="nav-link active text-white" id="pills-existing-tab" data-bs-toggle="pill" data-bs-target="#pills-existing" type="button" role="tab" aria-controls="pills-existing" aria-selected="false">Existing Account</button>
				  </li>
				</ul>
				<div className="tab-content d-flex justify-content-center" id="pills-tabContent">
				  <div className="tab-pane fade" id="pills-add" role="tabpanel" aria-labelledby="pills-add-tab" tabIndex="0">
				  	<div className="d-flex justify-content-center">
						<div className="payment-slip">
							<div className="card text-white border border-light my-2">
							  <div className="card-body">
							    <h5 className="card-title text-uppercase fw-bold">Withdrawal Slip</h5>
							    <form className="row g-3 needs-validation" onSubmit={handleSubmit} noValidate>
								  	<div className="text-start">
									  <label htmlFor="inputPaymentOption" className="form-label">Payment Option</label>
									  <select className="form-select" id="inputPaymentOption" required>
									  	<option disabled value="">--Select--</option>
									  	<option value="bank">Bank</option>
									  </select>
									  <div className="invalid-feedback">
								      	Please Select An Option.
								      </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputHolderName" className="form-label">Holder Name</label>
									  <input type="text" className={`form-control ${touchedAccount.holderName && !accountInfoValid.holderName ? 'is-invalid' : ''}`} id="inputHolderName" onInput={onHandleHolderName} value={accountInfo.holderName} placeholder="Holder Name" required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid Account Holder Name.
								      </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputBankName" className="form-label">Bank Name</label>
									  <input type="text" className={`form-control ${touchedAccount.bankName && !accountInfoValid.bankName ? 'is-invalid' : ''}`} id="inputBankName" onInput={onHandleBankName} value={accountInfo.bankName} placeholder="Bank Name" required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid Bank Name.
								      </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputBranch" className="form-label">Branch</label>
									  <input type="text" className={`form-control ${touchedAccount.branch && !accountInfoValid.branch ? 'is-invalid' : ''}`} id="inputBranch" onInput={onHandleBranchName} value={accountInfo.branch} placeholder="Branch" required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid Branch Name.
								      </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputAccountNumber" className="form-label">Account Number</label>
									  <input type="number" className={`form-control ${touchedAccount.accountNumber && !accountInfoValid.accountNumber ? 'is-invalid' : ''}`} id="inputAccountNumber" onInput={onHandleAccountNmbr} value={accountInfo.accountNumber} placeholder="Account Number" required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid Account Number.
								      </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputIfscNumber" className="form-label">IBAN/IFSC Number</label>
									  <input type="text" className={`form-control ${touchedAccount.ifscCode && !accountInfoValid.ifscCode ? 'is-invalid' : ''}`} id="inputIfscNumber" onInput={onHandleIfscCode} value={accountInfo.ifscCode} placeholder="IBAN/IFSC Number" required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid IFSC Number For Example : "ABCD0789876".
								      </div>
									</div>
								  	<div className="col-12">
								      <button className="btn btn-warning fw-bold text-uppercase w-100" type="submit" disabled={!accountInfoValid.holderName || !accountInfoValid.bankName || !accountInfoValid.branch || !accountInfoValid.accountNumber || !accountInfoValid.ifscCode}>Add Account</button>
								 	</div>
								</form>
							  </div>
							</div>
						</div>
					</div>
				  </div>
				  <div className="tab-pane fade show active" id="pills-existing" role="tabpanel" aria-labelledby="pills-existing-tab" tabIndex="0">
				  	<div className="d-flex justify-content-center">
						<div className="payment-slip">
							<div className="card text-white border border-light my-2">
							  <div className="card-body">
							    <h5 className="card-title text-uppercase fw-bold">Withdrawal Slip</h5>
							    <form className="row g-3 needs-validation" onSubmit={handleWithdrawalSubmit} noValidate>
								  	<div className="text-start">
									  <label htmlFor="inputAccountNumber" className="form-label">Account Number</label>
									  <input type="number" className={`form-control ${touched.accountNumber && !bankInfoValid.accountNumber ? 'is-invalid' : ''}`} id="inputAccountNumber" onInput={onHandleAccountNumber} placeholder="Account Number" required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid Account Number Between 11 To 16 Digits.
								      </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputAccountName" className="form-label">Account Name</label>
									  <input type="text" className={`form-control ${touched.accountName && !bankInfoValid.accountName ? 'is-invalid' : ''}`} id="inputAccountName" onInput={onHandleAccountName} placeholder="Account Name" required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid Account Name.
								      </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputAmount" className="form-label">Amount</label>
									  <input type="number" className={`form-control ${touched.amount && !bankInfoValid.amount ? 'is-invalid' : ''}`} id="inputAmount" onInput={onHandleAmount}  value={bankInfo.amount} placeholder="Amount" required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid Amount.
								      </div>
									</div>
									<div className="text-center">
										<div className="d-flex justify-content-center my-2">
											<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(100);
											}}>
											<p>100</p>
											</button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(200);
											}}><p>200</p></button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(500);
											}}><p>500</p></button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(1000);
											}}><p>1K</p></button>
										</div>
										<div className="d-flex justify-content-center my-2">
											<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(2000);
											}}><p>2K</p></button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(3000);
											}}><p>3K</p></button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(5000);
											}}><p>5K</p></button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(10000);
											}}><p>10K</p></button>
										</div>
									</div>
								  	<div className="col-12">
								      <button className="btn btn-warning fw-bold text-uppercase w-100" type="submit" disabled={!bankInfoValid.accountNumber || !bankInfoValid.amount || !bankInfoValid.accountName}>Withdraw</button>
								 	</div>
								</form>
							  </div>
							</div>
						</div>
					</div>
				  </div>
				</div>
			</div>
		</div>
	);
};

export default Withdrawal;