import { useState } from 'react';
import { toast } from 'react-toastify';
export const useWithdrawalSlip = () => {
	const [ bankInfo, setBankInfo ] = useState({
		accountNumber: '',
		amount: '',
		accountName: ''
	});

	const [ bankInfoValid, setBankInfoValid ] = useState({
		accountNumber: false,
		amount: false,
		accountName: false
	});

	const [touched, setTouched] = useState({
  		accountNumber: false,
  		amount: false,
  		accountName: false
  	});

	const handleWithdrawalSubmit = (event) => {
		event.preventDefault();
		if(!bankInfoValid.accountNumber || !bankInfoValid.amount || !bankInfoValid.accountName){
			toast.error('Please Check Error Fields.', {
		      theme: "colored",
		    });
		    return;
		} else {
			resetValidation();
		}
	};

	const resetValidation = () => {
		setBankInfo({ ...bankInfo, accountNumber: '', amount: '', accountName: '' });
		setBankInfoValid({ ...bankInfoValid, accountNumber: false, amount: false, accountName: false });
	};

	const onHandleAccountNumber = (e) => {
		const value = e.target.value;
		setBankInfo({ ...bankInfo, accountNumber: value });
		setBankInfoValid({ ...bankInfoValid, accountNumber: value.length < 12 || value.length > 14 ? false : true });
		setTouched((prev) => ({ ...prev, accountNumber: true }));
	};

	const onHandleAccountName = (e) => {
		const fullNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
		const value = e.target.value;
		const isValid = fullNameRegex.test(value) && value.length >= 5;
		setBankInfo({ ...bankInfo, accountName: value });
		setBankInfoValid({ ...bankInfoValid, accountName: isValid });
		setTouched((prev) => ({ ...prev, accountName: true }));
	};

	const onHandleAmount = (e) => {
		const value = e.target.value;
		setBankInfo({ ...bankInfo, amount: value });
		setBankInfoValid({ ...bankInfoValid, amount: value.length < 3  ? false : true });
		setTouched((prev) => ({ ...prev, amount: true }));
	};

	const calculateAmount = (amount) => {
		setBankInfo((prevBankInfo) => ({
			...prevBankInfo,
			amount: Number(prevBankInfo.amount || 0) + Number(amount) 
		}));
	};
	return{
		bankInfo,
		bankInfoValid,
		touched,
		onHandleAccountNumber,
		onHandleAccountName,
		onHandleAmount,
		calculateAmount,
		handleWithdrawalSubmit
	};
};