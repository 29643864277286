import { useLoginHook } from '../../hooks/loginHook';
import { useValidationHook } from '../../hooks/validationHook';
import { Modal } from 'bootstrap';
const LoginModal = (props) => {
	useValidationHook();
	const closeModal = () => {
        const modalElement = document.getElementById(props.id);
        const modal = Modal.getInstance(modalElement);
        if (modal) {
            modal.hide();
        }
        document.getElementById('closeModalButton').click();
    };
    const { onChangeUserName, onChangePassword, credentials, demoLogin, handleLogin, isPasswordValid, isUsernameValid, touched } = useLoginHook();
	return(
		<>
			<div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
		        <div className="modal-dialog">
		            <div className="modal-content bg-gradient-primary">
		                <div className="modal-header">
			                <img className="loginModal-logo" src="img/jsr-logo.png" width="100" alt="JSRPLAY247" />
			                <button id="closeModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
			                    <span aria-hidden="true" className="text-white">&times;</span>
			                </button>
		                </div>
		                <div className="modal-body">
			                <form className="needs-validation" onSubmit={handleLogin} noValidate>
			                    <div className="form-group">
			                        <label htmlFor="email" className="col-form-label">Username / Mobile Number</label>
			                        <input type="text" className={`form-control ${touched.username && !isUsernameValid ? 'is-invalid' : ''}`} id="loginemail" value={credentials.username} onInput={onChangeUserName} required/>
			                        <div className="invalid-feedback">
								      Please Enter Atleast 5 Characters.
								    </div>
			                    </div>
			                    <div className="form-group">
			                        <label htmlFor="password" className="col-form-label">Password:</label>
			                        <input type="password" className={`form-control ${touched.password && !isPasswordValid ? 'is-invalid' : ''}`} id="loginpassword" value={credentials.password} onInput={onChangePassword} required/>
			                    	<div className="invalid-feedback">
								       Please Enter Atleast 8 Characters.
								    </div>
			                    </div>
			                    <a href="/" className="forgot-pass text-center text-white text-decoration-none d-block m-3">Forgot Password?</a>
			                    <button type="submit" className="btn border-white text-white btn-block" disabled={!isUsernameValid || !isPasswordValid}>Login</button>
			                    <button type="submit" className="btn btn-light text-dark btn-block" onClick={demoLogin}>Login with Demo ID</button>
			                    <p className="text-center d-block m-4"> Don't have account? <a href="/" className="text-warning">Signup here.</a></p>
			                </form>
		                </div>
		            </div>
		        </div>
		    </div>
		</>
	)
}

export default LoginModal;